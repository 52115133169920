<template>
    <div class="record-list">
        <div
            v-if="!errorShow"
            class="right-panel"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <expand-filter
                    :formData="formData"
                    @clickBtn="clickBtn"
                    @datePickerBlur="datePickerBlur"
                    marginBottom="0px"
                    borderRadius="0px"
                    class="expand-filter-wrapper"
                ></expand-filter>
                <table-data
                    :config="table_config"
                    :tableData="table_data"
                    v-loading="loadingTable"
                    backgroundHeader="#FAFBFC"
                    :headerStyle="{height: '50px'}"
                    :rowStyle="{height: '50px'}"
                    ref="table"
                    id="table"
                    :to-top="false"
                >
                    <template #studentName="{data}">
                        <el-button
                            type="text"
                            @click="clickStudentName(data)"
                        >
                            {{ data.studentName }}
                        </el-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList()"
                />
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>
<script>
import {Loading, Pagination} from "common-local";
import TableData from "../Sub/TableData";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import {mapMutations, mapState} from "vuex";
import {downloadFile, formatTreeData} from "@/libs/utils";
import RecordsList from "@/libs/pageListOptimal.js";
export default {
    name: "RecordList",
    components: {
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
    },
    props: {
        basicForm: Object,
        activeItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,

            listQuery: {
                pageNum: 1,
                pageRow: 20,
                startTime: "",
                endTime: "",
                leaveStatus: "",
                leaveType: "",
                formId: "",
                organId: "",
                organType: "",
                onlyCode: "",
                jobScope: ["3"],
                studentId:"",

            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "datePick",
                        key: "dateArr",
                        value: [],
                        dateType: "daterange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "请选择开始时间",
                        endPlaceholder: "请选择结束时间",
                        defaultTime: ["00:00:00", "23:59:59"],
                        clearable: false,
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择状态",
                        key: "leaveStatus",
                        list: [
                            {
                                label: "未出校",
                                value: 0,
                            },
                            {
                                label: "待返校",
                                value: 1,
                            },
                            {
                                label: "逾期未返校",
                                value: 2,
                            },
                            {
                                label: "已返校",
                                value: 3,
                            },
                            {
                                label: "已失效",
                                value: 4,
                            },
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请假类型",
                        key: "leaveType",
                        list: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        // auth: ["schoolLeaveRecordAnalysis:record:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                    {
                        type: "enquiry",
                        text: "导出",
                        fn: "export",
                        // auth: ["schoolLeaveRecordAnalysis:record:export"],
                        loading: false,
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "姓名",
                        align: "center",
                        type: "slot",
                        slotName: "studentName",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: "center",
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "请假类型",
                        prop: "leaveTypeName",
                        align: "center",
                    },
                    {
                        label: "状态",
                        prop: "leaveStatus",
                        type: "function",
                        labelWidth: 120,
                        align: "center",
                        className: "text-spacing-reduction",
                        callBack(row) {
                            switch (Number(row.leaveStatus)) {
                                case 0:
                                    return `<div style='display:flex;align-items: center; justify-content: center;'><div style="background:#F23333;height:6px;width:6px;border-radius: 50%; margin-right:5px" class='round'></div><div>未出校</div></div>`;
                                case 1:
                                    return `<div style='display:flex;align-items: center; justify-content: center;'><div style="background:#F0C739;height:6px;width:6px;border-radius: 50%; margin-right:5px" class='round'></div><div>待返校</div></div>`;
                                case 2:
                                    return `<div style='display:flex;align-items: center; justify-content: center;'><div style="background:#EC621D;height:6px;width:6px;border-radius: 50%; margin-right:5px" class='round'></div><div>逾期未返校</div></div>`;
                                case 3:
                                    return `<div style='display:flex;align-items: center; justify-content: center;'><div style="background:#05CB00;height:6px;width:6px;border-radius: 50%; margin-right:5px" class='round'></div><div>已返校</div></div>`;
                                case 4:
                                    return `<div style='display:flex;align-items: center; justify-content: center;'><div style="background:#C4C4C4;height:6px;width:6px;border-radius: 50%; margin-right:5px" class='round'></div><div>已失效</div></div>`;
                            }
                        },
                    },
                    {
                        label: "请假日期",
                        prop: "appointTime",
                        labelWidth: "280",
                        align: "center",
                    },
                    {
                        label: "请假事由",
                        prop: "leaveReason",
                        labelWidth: "120",
                        align: "center",
                    },
                    {
                        label: "请假天数",
                        prop: "leaveDuration",
                        labelWidth: "120",
                        align: "center",
                    },
                    {
                        labelDescription: "实际出校（开始）时间",
                        label: "出校时间",
                        prop: "realStartTime",
                        labelWidth: "170",
                        type: "showTip",
                        align: "center",
                    },
                    {
                        labelDescription: "实际入校（结束）时间",
                        label: "入校时间",
                        prop: "realEndTime",
                        labelWidth: "170",
                        type: "showTip",
                        align: "center",
                    },
                    {
                        labelDescription:
                            "有实际出校、入校时间则按照入校时间-出校时间计算显示，没有实际出校、入校时间则按照请假日期的结束时间-开始时间计算显示",
                        label: "实际天数",
                        prop: "realDuration",
                        labelWidth: "120",
                        type: "showTip",
                        align: "center",
                    },
                    {
                        label: "备注",
                        prop: "remark",
                        labelWidth: "auto",
                        align: "center",
                    },
                    {
                        label: "操作人",
                        prop: "handCancelLeaveBy",
                        labelWidth: "auto",
                        align: "center",
                    },
                    {
                        label: "操作时间",
                        prop: "handCancelLeaveTime",
                        labelWidth: "170",
                        align: "center",
                        type: "function",
                        callBack(row) {
                            if (row.handCancelLeaveTime) {
                                return `${row.handCancelLeaveTime.substr(
                                    0,
                                    16,
                                )}`;
                            } else {
                                return "-";
                            }
                        },
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [],
            total: 0,
        };
    },
    created() {
        // 处理一下筛选项
        console.log(this.$store.state.schoolId, "----");
        if (
            this.$store.state.schoolId == 311 ||
            this.$store.state.schoolId == 222
        ) {
            this.formData.data = this.formData.data.map((item) => {
                if (item.key == "leaveStatus") {
                    item.list.splice(2, 1);
                }
                return item;
            });
        }
        this.init();
        this.getList();
    },
    mounted() {
        this.table_config.height =
            document.body.clientHeight - this.$refs.table.$el.offsetTop - 135;
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
        gradeLevelName() {
            let gradeLevelName = this.$store.state.gradeLevelName;
            if (gradeLevelName) {
                gradeLevelName = gradeLevelName.replace(/&lt;|</g, "-");
                return gradeLevelName.split("-");
            } else {
                return [];
            }
        },
    },
    methods: {
        ...mapMutations(["setOAQueryData"]),
        init() {
            let index = this.gradeLevelName.findIndex((i) => i === "级部");
            if (index !== -1) {
                let idx = this.table_config.thead.findIndex(
                    (i) => i.prop === "onlyCode",
                );
                this.table_config.thead.splice(idx + 1 + index, 0, {
                    prop: "departmentName",
                    label: "级部",
                    labelWidth: "120",
                    className: "text-spacing-reduction",
                });
            }
            this.setDefaultTime();
            this.getLeaveType();
            this.getOrgList();
            //权限
            // console.log(this.permissions, '有值吗');
            if (this.permissions.indexOf("willAttend:info") !== -1) {
                this.detailShow = true;
            }
        },
        /**
         * @Description: 设置默认时间
         * @DoWhat: 默认显示当月1号到当前时间（当天）
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-01 10:47:42
         */
        setDefaultTime() {
            let startDate = this.$moment()
                .startOf("month")
                .format("YYYY-MM-DD");
            let currentDate = this.$moment().format("YYYY-MM-DD");
            this.listQuery.startTime = startDate + " 00:00";
            this.listQuery.endTime = currentDate + " 23:59";
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [startDate, currentDate];
                }
            });
        },

        /**
         * @Description: 查询/重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:10:40
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset":
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = "";
                        item.value = "";
                    });
                    this.listQuery.organType = "";
                    this.setDefaultTime();
                    this.getList(1);
                    break;
                case "export":
                    this.handleExport();
                    break;
                default:
                    break;
            }
        },
        clickStudentName(data) {
            this.setOAQueryData({
                id: data.applyId, // 申请id
                dataTitle: data.formName, // 申请title
                backData: {
                    name: this.$route.name,
                    // 跳转返回到请假记录页面增加个标识
                    query: {
                        isGotoLeaveRecordAnalysis: 1,
                    },
                },
            });
            //存储滚动条高度
            let recordsList = new RecordsList();
            recordsList.set(this.$route.name, {
                tableScrollTop:
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop,
            });
            this.$router.push({
                name: "CoworkingCampusOAOAApprovalApplyDetail",
                query: {
                    activeName: "1",
                    activeItem: JSON.stringify(this.activeItem),
                },
            });
        },
        handleExport() {
            let obj = {
                jobScope: ["3"],
                organType: this.listQuery.organType,
                studentId : this.basicForm.id
            };
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    if (item.value && item.value.length === 2) {
                        obj.startTime = item.value[0] + " 00:00:00";
                        obj.endTime = item.value[1] + " 23:59:59";
                    }
                } else {
                    obj[item.key] = item.value;
                }
            });
            console.log(obj, "obj");
            if (obj.organId) {
                obj.organId = obj.organId.slice(-1).toString();
            }
            let index = this.formData.btnList.findIndex(
                (i) => i.fn === "export",
            );
            this.formData.btnList[index].loading = true;
            downloadFile(
                {
                    url: "/school/leaveRecord/export",
                    method: "post",
                    form: obj,
                },
                () => {
                    this.formData.btnList[index].loading = false;
                    this.$message.success("导出成功");
                },
                () => {
                    this.formData.btnList[index].loading = false;
                },
            );
        },
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:11:20
         */
        getList(isPaging) {
            let data = {};
            if (isPaging) {
                this.listQuery.pageNum = 1;
            }
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            this.loadingTable = true;
            if (this.listQuery.organId) {
                this.listQuery.organId = this.listQuery.organId
                    .slice(-1)
                    .toString();
            }
            this.listQuery.studentId = this.basicForm.id;
            this._fet(
                "/school/leaveRecord/leaveRecordList",
                this.listQuery,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.total = res.data.data.totalCount;
                    this.table_data = res.data.data.list;

                    this.table_data.map((i) => {
                        //修改列表请假日期的格式
                        let appointTime = this.computedTime(
                            i.leaveStart,
                            i.leaveEnd,
                        );
                        this.$set(i, "appointTime", appointTime);

                        //处理实际出校时间
                        if (i.realStart) {
                            let realStartTime = i.realStart.slice(
                                0,
                                i.realStart.length - 3,
                            );
                            this.$set(i, "realStartTime", realStartTime);
                        } else {
                            this.$set(i, "realStartTime", "-");
                        }
                        //处理实际入校时间
                        if (i.realEnd) {
                            let realEndTime = i.realEnd.slice(
                                0,
                                i.realEnd.length - 3,
                            );
                            this.$set(i, "realEndTime", realEndTime);
                        } else {
                            this.$set(i, "realEndTime", "-");
                        }
                    });

                    //赋值--存储滚动条高度
                    this.$nextTick(() => {
                        let recordsList = new RecordsList();
                        if (recordsList.get(this.$route.name)) {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop =
                                recordsList.get(
                                    this.$route.name,
                                ).tableScrollTop;
                        }
                        console.log(
                            recordsList.get(this.$route.name),
                            "获取滚动条高度",
                            this.$refs.table.$refs.multipleTable.bodyWrapper
                                .scrollTop,
                        );
                        //移除--存储滚动条高度
                        recordsList.remove(this.$route.name);
                    });
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.loadingTable = false;
            });
        },
        /**
         * @Description: 处理请假日期的显示格式
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 20:54:11
         */
        computedTime(leaveStart, leaveEnd) {
            // 当前年份
            let curYear = this.$moment().format("YYYY");
            // 开始日期年份
            let startYear = this.$moment(leaveStart).format("YYYY");
            // 结束日期年份
            let endYear = this.$moment(leaveEnd).format("YYYY");
            let format = "";
            if (curYear === startYear && curYear && endYear) {
                format = "MM-DD HH:mm";
            } else {
                format = "YYYY-MM-DD HH:mm";
            }
            let startDate = this.$moment(leaveStart).format(format);
            let endDate = this.$moment(leaveEnd).format(format);
            return `${startDate} - ${endDate}`;
        },
        /**
         * @Description: 获取请假类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 15:28:41
         */
        getLeaveType() {
            this._fet("/oa/schoolVacation/listByConditionStatis", {
                schoolId: this.$store.state.schoolId,
                deleteStatus: 0,
                type: "2",
            }).then((res) => {
                if (res.data.code === "200") {
                    this.formData.data.forEach((item) => {
                        if (item.key === "leaveType") {
                            item.list = res.data.data.map((i) => {
                                return {
                                    label: i.vacationName,
                                    value: i.id,
                                };
                            });
                        }
                    });
                }
            });
        },
        /**
         * @Description: 获取表单下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 15:35:32
         */
        getFormList() {
            this._fet("/oa/schoolFormInfo/permissionListByCondition", {
                status: "1",
                releaseStatus: "2",
                userPopulation: "1",
                formType: "1",
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    const index = this.formData.data.findIndex(
                        (i) => i.key === "formId",
                    );
                    if (index !== -1) {
                        this.formData.data[index].list = res.data.data.map(
                            (i) => ({
                                value: i.id,
                                label: i.formName,
                            }),
                        );
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取组织架构班级接口
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 15:43:17
         */
        getOrgList() {
            this._fet("/school/schoolOrgan/listAuthByCondition", {
                schoolId: this.$store.state.schoolId,
                jobScope: [3],
            }).then((res) => {
                if (res.data.code === "200") {
                    const index = this.formData.data.findIndex(
                        (i) => i.type === "cascader",
                    );
                    if(this.formData.data[index]) {
                        this.formData.data[index].list = formatTreeData(
                            res.data.data.list,
                            "id",
                            "parentOrg",
                        );
                    }
                    
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 筛选条件-选择时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 17:05:22
         */
        datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });

            let [startTime, endTime] = dateArr.value;
            let status = this.checkTime(startTime, endTime);
            if (status) {
                this.listQuery.startTime = startTime + " 00:00";
                this.listQuery.endTime = endTime + " 23:59";
            } else {
                dateArr.value = [
                    this.listQuery.startTime,
                    this.listQuery.endTime,
                ];
            }
        },
        /**
         * @Description: 筛选时间校验，不能超过6个月
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 17:06:47
         */
        checkTime(startTime, endTime) {
            let start = new Date(startTime);
            let end = new Date(endTime);
            let status = true;
            let endFullYear = end.getFullYear();
            let startFullYear = start.getFullYear();
            let endMonth = end.getMonth();
            let startMonth = start.getMonth();

            if (endFullYear != startFullYear) {
                if (endMonth + 12 - startMonth > 6) {
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    status = false;
                }

                if (endMonth + 12 - startMonth == 6) {
                    if (end.getDate() > start.getDate()) {
                        this.$message.error("开始时间和结束时间不能超过6个月");
                        status = false;
                    }
                }
            }
            if (end.getMonth() - start.getMonth() > 6) {
                this.$message.error("开始时间和结束时间不能超过6个月");
                console.log("33333");
                return (status = false);
            } else if (end.getMonth() - start.getMonth() == 6) {
                if (end.getDate() > start.getDate()) {
                    console.log("44444");
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    return (status = false);
                }
            }
            return status;
        },
    },
};
</script>

<style lang="scss" scoped>
.record-list {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
    // position: relative;
    // margin: 0 auto;
    width: 920px !important;
}
.expand-filter-wrapper {
    border-bottom: 1px solid #f2f4f7;
    ::v-deep .el-input__inner::placeholder {
        color: #C8CCD3 !important;
    }
}
.content-wrapper {
    margin: 10px;
}
.status {
    display: flex;
    background-color: pink;
    .round {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: red;
        margin-right: 5px;
    }
}
.round {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: red;
    margin-right: 5px;
}
</style>
